<template>


  <b-card-actions ref="list" title="" action-refresh @refresh="refresh">
    <b-modal
        id="share-document"
        ok-only
        :ok-title="$t('Proceed')"
        centered
        size="lg"
        :title="$t('Sharing document')"
    >
      <b-card-text>{{ sharingDescription }}</b-card-text>
      <b-row class="nmb">
        <b-col lg="2" class="mt-06"><h5 class="text-capitalize nmb v-align-middle">{{ $t('URL') }}:</h5></b-col>
        <b-col
            lg="10"
        >
          <!-- button on right -->
          <b-input-group>
            <b-form-input :value="this.shared_address" disabled/>
            <b-input-group-append>
              <b-button variant="outline-primary" v-b-tooltip.hover.v-primary
                        :title="$t('Copy to clipboard')" @click="copyAddress()">
                <feather-icon icon="ClipboardIcon"/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>

      </b-row>
      <b-row>
        <spacer height="10"></spacer>
      </b-row>

      <b-row class="nmb">
        <b-col lg="2" class="mt-06"><h5 class="text-capitalize nmb v-align-middle">{{ $t('Password') }}:</h5></b-col>
        <b-col
            lg="10"
        >
          <!-- button on right -->
          <b-input-group>
            <b-form-input :value="this.shared_password" disabled/>
            <b-input-group-append>
              <b-button variant="outline-primary" v-b-tooltip.hover.v-primary
                        :title="$t('Copy to clipboard')" @click="copyPassword()">
                <feather-icon icon="ClipboardIcon"/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>

      </b-row>

      <b-row class="nmb">
        <b-col lg="6" class="mt-06"><h5 class="nmb v-align-middle">{{ $t("The password will be valid till the") }}:</h5>
        </b-col>
        <b-col
            lg="4" style="text-align: left;"
        >
          <!-- button on right -->
          <b-input-group class="mt-06">
            {{ this.password_expiration | moment("YYYY-MM-DD HH:mm:ss") }}
          </b-input-group>
        </b-col>

      </b-row>


    </b-modal>
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <export-excel
              :data="rows"
              :columns="excel_columns"
              :file-name="'documents'"
              :file-type="'xlsx'"
              :sheet-name="'sheetname'"
          >
            <feather-icon icon="DownloadIcon"/>
          </export-excel>

          <b-button
              variant="primary"
              class="button-inline-spacing-2"
              @click="add_section()"
              :disabled="editDisabled"
          >
            <span class="text-nowrap">{{ $t("Add Document") }}</span>
          </b-button>


        </b-col>

        <!-- Search -->
        <b-col
            cols="12"
            md="6"
        >

          <b-form-input
              v-model="searchTerm"
              :placeholder="$t('message.SearchPlaceholder')"
              type="text"
              class="d-inline-block"
          />
        </b-col>
      </b-row>

    </div>


    <!-- alert -->
    <!--b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
      </div>
    </b-alert-->

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">


    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >

      <!-- Slot: Table Column -->
      <template
          slot="table-column"
          slot-scope="props"
      >


        <span>
          {{ $t(props.column.label) }}
        </span>
      </template>

      <!-- Slot: Table Row -->
      <template
          slot="table-row"
          slot-scope="props"
      >


        <!-- Column: Description -->
        <span
            v-if="props.column.field === 'description'"
            class="text-nowrap align-middle"
        >
                    <span>{{ props.row.description }}</span>

        </span>


        <!-- Column: Name -->
        <span
            v-if="props.column.field === 'revisioned_at'"
            class="text-nowrap v-align-middle"
        >
          <span>{{ props.row.revisioned_at | moment("YYYY-MM-DD HH:mm:ss") }}</span>
        </span>


        <!-- Column: Name -->
        <span
            v-if="props.column.field === 'revision'"
            class="text-nowrap v-align-middle"
        >
          <span>{{ props.row.revision }}</span>
        </span>


        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'" class="button-inline-spacing">


    <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="btn-icon"
        v-b-tooltip.hover.v-primary
        :title="$t('edit')"
        @click="edit(props.row.id)"
        :disabled="editDisabled"
    >
      <feather-icon icon="Edit2Icon"/>
    </b-button>


          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              v-b-tooltip.hover.v-primary
              :title="$t('download')"
              @click="download(props.row)"

          >
      <feather-icon icon="DownloadIcon"/>
    </b-button>

             <b-button
                 v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                 variant="outline-primary"
                 class="btn-icon"
                 v-b-tooltip.hover.v-primary
                 :title="$t('share')"
                 @click="openShareModal(props.row)"
                 :disabled="editDisabled"

             >
      <feather-icon icon="Share2Icon"/>
    </b-button>
            <b-button  v-if="$can('manage', 'Basic HR')"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                v-b-tooltip.hover.v-primary
                :title="$t('delete')"
                @click="deleteDocument(props.row)"
                       :disabled="editDisabled"

            >
      <feather-icon icon="TrashIcon"/>
    </b-button>


        </span>

        <!-- Column: Common -->
        <span v-else>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >

        <div class="d-flex justify-content-between flex-wrap">


          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('message.pagelength') }}
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['3','5','10','15']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
          </div>
          <div>

            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>

            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <template #code>
    </template>
  </b-card-actions>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {BCardActions} from "@core/components/b-card-actions";
import {
  BAvatar,
  BBadge,
  BCardText,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BAlert,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import {codeI18n} from './code'
import useJwt from '@/auth/jwt/useJwt'
import UserService from "@/basic/userService";
import Ripple from 'vue-ripple-directive'
import {formatDate} from '@/basic/filters.js'

import {BButton} from "bootstrap-vue";
import ExportExcel from "@/views/components/excel-export/ExportExcel";

import Vue from "vue";
import i18n from "@/libs/i18n";
import {environment} from "@/auth/environment";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {VBTooltip} from 'bootstrap-vue'
import moment from 'moment';
import ability from "@/libs/acl/ability";

Vue.use(ExportExcel);

export default {
  components: {
    BCardCode,
    BCardActions,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      pageLength: 15,
      codeI18n,
      dir: false,
      document_section_id: this.$route.params.document_section_id,
      document_sections: [{id: this.$route.params.document_section_id, name: ""}],
      document_section: {id: "1", name: "beta"},
      shared_description: "",
      shared_address: "",
      shared_password: "",
      password_expiration: "",
      columns: [
        {
          label: 'description',
          field: 'description',
        },
        {
          label: 'Revision',
          field: 'revision',
        },
        {
          label: 'revisioned_at',
          field: 'revisioned_at',
        },

        {
          label: 'action',
          field: 'action',
        },

      ],
      excel_columns: [
        {
          label: i18n.t('description'),
          field: 'description',
        },
        {
          label: i18n.t('revision'),
          field: 'revision',
        },
        {
          label: i18n.t('revisioned_at'),
          field: 'revisioned_at',
        },


      ],
      rows: [],
      searchTerm: '',
      editDisabled: !ability.can("manage", "Basic Library"),

    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    sharingDescription() {
      return i18n.t("You are sharing the document") + ": \"" + this.shared_description + "\". " + i18n.t("The document will be downloadable by anyone knowing the address and the password. The password will expire in 24 hours.");
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    refresh() {
      console.log("refresh")
      this.$refs["list"].showLoading = true
      let company_id = store.state.userCompany.id;

      const userService = new UserService(useJwt)



      userService.documents(this.document_section_id, {}).then(response => {
        this.rows = response.data.data.documents
        this.editDisabled = (response.data.data.can_write && this.editDisabled) ;
        console.log('rows', this.rows)
        this.$refs["list"].showLoading = false
      })

      userService.document_sections(company_id, {}).then(response => {
        this.document_sections = response.data.data
      })

      userService.document_section(this.document_section_id, {}).then(response => {
        this.document_section = response.data.data.section
      })

      this.document_sections.forEach((element, index) => {
        if (this.document_sections[index].document_section_id > 0){
          this.document_sections[index].id = this.document_sections[index].document_section_id;
        }

      });

    },


    setSection(section) {
      this.document_section_id = section.id;
      this.refresh()
    },
    add_section() {
      this.$router.push({name: 'document', params: {id: 'new', section_id: this.document_section_id}})
    },
    edit(id) {
      this.$router.push({name: 'document', params: {id: id, section_id: this.document_section_id}})
    },
    download(row) {
      console.log('download row', row.attachment.path)
      const link = document.createElement("a");
      link.href = environment.baseURL + "/documents/download_attachment/" + row.attachment.id + "/" + row.attachment.otp
      link.download = row.attachment.path;
      link.click();
      URL.revokeObjectURL(link.href);

    },
    openShareModal(row) {

      if (this.password_expiration === "" ||  moment().diff(this.password_expiration, 'minutes') > 0) {
        const userService = new UserService(useJwt)
        this.$refs["list"].showLoading = true

        userService.share_attachment(row.id).then(response => {
          this.shared_description = row.description
          this.shared_address = environment.baseDomain + "share/" + response.data.data.attachment.otp
          this.shared_password = response.data.data.attachment.password
          this.password_expiration = response.data.data.attachment.password_expires_at

          this.$bvModal.show('share-document')
          this.$refs["list"].showLoading = false

        })

      } else {
        this.$bvModal.show('share-document')

      }

    },
    copyAddress() {
      navigator.clipboard.writeText(this.shared_address);
    },
    copyPassword() {
      navigator.clipboard.writeText(this.shared_password);
    },
    deleteDocument(row) {
      let confirmed = false;
      let message = i18n.t('You are deleting the document') + ": \"" + row.description + "\". " + i18n.t("Are you sure?");

      this.$bvModal
          .msgBoxConfirm(message, {
            title: i18n.t('Confirmation Required'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: i18n.t('Proceed'),
            cancelTitle: i18n.t('Cancel'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            confirmed = value

            if (confirmed) {
              const userService = new UserService(useJwt)
              userService.delete_document(row.id).then(response => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: i18n.t('Document deleted'),
                    icon: 'AlertIcon',
                    variant: 'success',
                    text: i18n.t('Document deleted with success'),
                  },
                })
                this.refresh();
              })
            }
          })
    },

  },
  mounted() {
    this.$refs["list"].showLoading = true
    console.log("mounted", this.document_section_id);

    this.unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'selected_company') {
        console.log(`Updating selected_company to ${state.userCompany.id}`);
        this.refresh();
      }
    });
    this.refresh();
    const userService = new UserService(useJwt)
    let company_id = store.state.userCompany.id;

    //userService.document_sections(company_id, {}).then(response => {
   //   this.document_sections = response.data.data
   // })

    //this.$http.get('/good-table/basic')
    //.then(res => { this.rows = res.data })
  },
  setup() {
    return {formatDate}

  },
  beforeDestroy() {
    this.unsubscribe();
  }
}
</script>
